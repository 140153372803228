import NProgress from 'nprogress'; // progress bar
import router, { asyncRoutes } from './index';
import store from '../store';
import authRouter from './modules/auth';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

/**
 * Generate white list
 */
const whiteList = (['/landing', '/land']
  .concat(Array.from(authRouter, (route) => route.path))
  .concat(Array.from(authRouter, (route) => route.alias)))
  .filter((route) => route); // remove undefined element
console.log('[router.whiteList]', whiteList);

/**
 * Check user has permission for this routes.
 * 'admin' permission passed directly.
 * @param {Array} roles
 * @param {Array} permissionRoles
 */
// eslint-disable-next-line no-unused-vars
function hasPermission(roles, permissionRoles) {
  try {
    if (roles.includes('any')) return true;
    if (roles.includes('admin')) return true;
    return permissionRoles.some((role) => roles.includes(role))
  } catch (e) {
    return false
  }
}

async function getUserAuthorities(to,from,next) {
  let listRoles = undefined
  try {
    listRoles = router.app.$keycloak.tokenParsed.realm_access.roles;
  } catch(e) {
    console.log(e)
  }
  if (!listRoles) {
    console.log('no list of roles')
      next('/401')
  } else {
      store.commit('SET_AUTHORITIES', listRoles);
      store.commit('SET_ROUTES', asyncRoutes);
      // console.log(flatten)
      if(to.path === '/401' || to.path === '/') {
        console.log('path:', to.path)
        next()
      }else if (hasPermission(to.meta.roles, listRoles)) {
        next();
      } else {
        console.log('no permission')
        next('/401')
      }
  }
  NProgress.done();
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if(router.app.$keycloak) {
    while (router.app.$keycloak.createLoginUrl === null) {
      await sleep(100)
    }
  }
  if (router.app.$keycloak && router.app.$keycloak.authenticated) {
    const profile = await router.app.$keycloak.loadUserProfile();
    console.log(profile);
    store.commit('SET_USER_INFO', {
      user: profile.username,
      name: `${profile.firstName} ${profile.lastName}`,
    });
    getUserAuthorities(to,from,next)
  } else {
    if(router.app.$keycloak) {
      let isConfirm = confirm(`Current tenant is ${localStorage.realm}. Do you want to proceed with this tenant and login?`)
      if(isConfirm) {
        const loginUrl = router.app.$keycloak.createLoginUrl();
        window.location.replace(loginUrl);
      } else {
        localStorage.clear();
        location.reload();
      }
    } else {
      next();
    }
    NProgress.done();
  }
});

router.afterEach(async () => {
  NProgress.done();
});
