import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import axios from 'axios';
import VueAxios from 'vue-axios';

import vuetify from './plugins/vuetify';
import i18n from './locale'; // Internationalization

import App from './App.vue';
import Tenant from './Tenant.vue';
import router from './router';
import store from './store';

import './router/permission';

import UUID from "vue-uuid";

Vue.use(UUID);

Vue.use(VueAxios, axios);

// let urlParams = new URLSearchParams(window.location.search);
// console.log(urlParams.has('realm'));
// console.log(urlParams.get('realm'));

// if(urlParams.has('realm')) {
//   localStorage.realm = urlParams.get('realm')
// }

// Vue.use(VueKeyCloak, {
//   init: {
//     onLoad: 'login-required',
//   },
//   config: {
//     realm: localStorage.realm, //process.env.VUE_APP_KEYCLOAK_REALM,
//     url: process.env.VUE_APP_KEYCLOAK_URL,
//     clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
//   },
//   onReady: () => {
//     new Vue({
//       router,
//       store,
//       i18n,
//       vuetify,
//       render: (h) => h(App),
//     }).$mount('#app');
//   },
// });

if(localStorage.realm) {
  Vue.use(VueKeyCloak, {
    init: {
      onLoad: 'check-sso',
    },
    config: {
      realm: localStorage.realm, //process.env.VUE_APP_KEYCLOAK_REALM,
      url: process.env.VUE_APP_KEYCLOAK_URL,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    },
    onReady: () => {
      new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    },
  });
} else {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(Tenant),
  }).$mount('#app');
}
