import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
// import vuetifyRouter from '@/demo/router/vuetify';
// import nestedRouter from '@/demo/router/nested';
// import componentsRouter from '@/demo/router/components';
// import authRouter from './modules/auth';
// import errorsRouter from './modules/errors';
// import permissionRouter from './modules/permission';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

export const constantRoutes = [
  {
    path: '/tenant',
    alias: '/tenant',
    name: 'tenant',
    component: () => import('@/Tenant.vue'),
  },
  {
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    component: () => import('@/views/modules/Dashboard/index.vue'),
    meta: {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      roles: ['any']
    },
  },
  {
    path: "/user-management",
    alias: '/user-management',
    name: 'user-management',
    component: () => import('@/views/modules/Users/index.vue'),
    meta: {
      title: 'Admin Accounts',
      icon: 'mdi-account-key',
      roles: ['ROLE_READ_USER', 'ROLE_CREATE_USER', 'ROLE_UPDATE_USER', 'ROLE_ADD_GROUPTOUSER', 'ROLE_SEARCH_USER']
    },
  },
  {
    path: "/group-management",
    alias: '/group-management',
    name: 'group-management',
    beforeEnter() {
      window.open(`${process.env.VUE_APP_KEYCLOAK_URL}/admin/${localStorage.realm}/console/#/realms/${localStorage.realm}/roles`,
      '_blank');
    },
    // component: () => import('@/views/modules/Groups/index.vue'),
    meta: {
      title: 'Groups & Roles',
      icon: 'mdi-tie',
      roles: ['ROLE_READ_GROUP', 'ROLE_ADD_ROLETOGROUP', 'ROLE_DELETE_ROLETOGROUP', 'ROLE_CREATE_GROUP', 'ROLE_SEARCH_GROUP',
              'ROLE_READ_ROLE', 'ROLE_SEARCH_ROLE', 'ROLE_UPDATE_ROLE', 'ROLE_CREATE_ROLE', 'ROLE_DELETE_ROLE']
    },
  },
  {
    path: '/member-management',
    alias: '/member-management',
    name: 'member-management',
    component: () => import('@/views/modules/Members/index.vue'),
    meta: {
      title: 'Clients',
      icon: 'mdi-account-multiple',
      roles: ['ROLE_READ_MEMBER', 'ROLE_CREATE_MEMBER', 'ROLE_UPDATE_MEMBER', 'ROLE_READ_ACCOUNT', 'ROLE_CREATE_ACCOUNT', 'ROLE_UPDATE_ACCOUNT']
    },
    children: [
      {
        path: '/member-management/client',
        name: 'client',
        component: () => import('@/views/modules/Members/client.vue'),
        meta: {
          title: 'Clients',
          roles: ['ROLE_READ_MEMBER', 'ROLE_CREATE_MEMBER', 'ROLE_UPDATE_MEMBER']
        },
      },
      {
        path: '/member-management/account',
        name: 'account',
        component: () => import('@/views/modules/Members/account.vue'),
        meta: {
          title: 'Accounts',
          roles: ['ROLE_READ_ACCOUNT', 'ROLE_CREATE_ACCOUNT', 'ROLE_UPDATE_ACCOUNT']
        },
      },
      {
        path: "/member-management/account/:id",
        name: 'account-transaction',
        component: () => import('@/views/modules/Members/transaction.vue'),
        props: true,
        hidden: true,
        meta: {
          title: 'Account Transactions',
          roles: ['ROLE_READ_TRANSACTIONS']
        },
      },
    ],
  },
  {
    path: '/organization',
    alias: '/organization',
    name: 'organization',
    component: () => import('@/views/modules/Organization/index.vue'),
    meta: {
      title: 'Organizations',
      icon: 'mdi-cube',
      roles: ['ROLE_READ_ORGANIZATION', 'ROLE_READ_DEPARTMENT', 'ROLE_CREATE_DEPARTMENT', 'ROLE_UPDATE_DEPARTMENT']
    },
    children: [
      {
        path: '/organization/community',
        name: 'community',
        component: () => import('@/views/modules/Organization/community.vue'),
        meta: {
          title: 'Communities',
          roles: ['ROLE_READ_ORGANIZATION']
        },
      },
      {
        path: '/organization/department',
        name: 'department',
        component: () => import('@/views/modules/Organization/department.vue'),
        meta: {
          title: 'Departments',
          roles: ['ROLE_READ_DEPARTMENT', 'ROLE_CREATE_DEPARTMENT', 'ROLE_UPDATE_DEPARTMENT']
        },
      },
    ],
  },
  {
    path: '/wallets',
    alias: '/wallets',
    name: 'wallets',
    hidden: true,
    component: () => import('@/views/modules/Wallet/index.vue'),
    meta: {
      title: 'Wallets',
      icon: 'mdi-wallet-travel',
      roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPER_WALLET']
    },
    children: [
      {
        path: '/wallets/super-wallet',
        name: 'super-wallet',
        component: () => import('@/views/modules/Wallet/super-wallet.vue'),
        meta: {
          title: 'Super Wallets',
        },
      },
      {
        path: '/wallets/master-wallet',
        name: 'master-wallet',
        component: () => import('@/views/modules/Wallet/master-wallet.vue'),
        meta: {
          title: 'Master Wallets',
        },
      },
      {
        path: '/wallets/team-wallet',
        name: 'team-wallet',
        component: () => import('@/views/modules/Wallet/team-wallet.vue'),
        meta: {
          title: 'Team Wallets',
        },
      },
      {
        path: '/wallets/reload-wallet',
        name: 'reload-wallet',
        component: () => import('@/views/modules/Wallet/reload-wallet.vue'),
        meta: {
          title: 'Reload Wallets',
        },
      },
      {
        path: '/wallets/credit-account',
        name: 'credit-account',
        component: () => import('@/views/modules/Wallet/credit-account.vue'),
        meta: {
          title: 'Credit Accounts',
        },
      },
      {
        path: '/wallets/voucher',
        name: 'voucher',
        component: () => import('@/views/modules/Wallet/voucher.vue'),
        meta: {
          title: 'Vouchers',
        },
      },
      {
        path: '/wallets/transfer-credits',
        name: 'transfer-credits',
        component: () => import('@/views/modules/Wallet/transfer-credits.vue'),
        meta: {
          title: 'Transfer Credits',
        },
      },
      {
        path: '/wallets/return-credits',
        name: 'return-credits',
        component: () => import('@/views/modules/Wallet/return-credits.vue'),
        meta: {
          title: 'Return Credits',
        },
      },
    ],
  },
  {
    path: '/transaction',
    alias: '/transaction',
    name: 'transaction',
    component: () => import('@/views/modules/Transactions/index.vue'),
    meta: {
      title: 'Transactions',
      icon: 'mdi-folder-outline',
      roles: ['ROLE_READ_TRANSACTIONS']
    },
    children: [
      {
        path: '/transaction/all',
        name: 'all-transactions',
        component: () => import('@/views/modules/Transactions/allTransactions.vue'),
        meta: {
          title: 'All Transactions',
          roles: ['ROLE_READ_TRANSACTIONS']
        },
      },
      {
        path: '/transaction/load-terminal',
        name: 'load-terminal',
        component: () => import('@/views/modules/Transactions/loadTerminal.vue'),
        meta: {
          title: 'Load Terminal',
          roles: ['ROLE_READ_TRANSACTIONS']
        },
      },
    ],
  },
  {
    path: '/settings',
    alias: '/settings',
    name: 'settings',
    component: () => import('@/views/modules/Settings/index.vue'),
    meta: {
      title: 'System Settings',
      icon: 'mdi-shape-plus',
      roles: ['ROLE_READ_LIMIT', 'ROLE_CREATE_WALLET', 'ROLE_READ_WALLET', 'ROLE_UPDATE_WALLET', 'ROLE_CREATE_WALLET_TYPE', 'ROLE_READ_WALLET_TYPE', 'ROLE_UPDATE_WALLET_TYPE', 'ROLE_READ_TRANSACTIONS']
    },
    children: [
      {
        path: '/settings/account-limit',
        name: 'account-limit',
        component: () => import('@/views/modules/Settings/account-limit.vue'),
        meta: {
          title: 'Account Limit',
          roles: ['ROLE_READ_LIMIT']
        },
      },
      {
        path: '/settings/system-parameters',
        name: 'system-parameters',
        component: () => import('@/views/modules/Settings/system-parameters.vue'),
        meta: {
          title: 'System Parameters',
          roles: ['ROLE_READ_LIMIT']
        },
      },
      {
        path: '/settings/savings-products',
        name: 'savings-products',
        component: () => import('@/views/modules/Settings/savings-products.vue'),
        meta: {
          title: 'Wallets',
          roles: ['ROLE_CREATE_WALLET', 'ROLE_READ_WALLET', 'ROLE_UPDATE_WALLET']
        },
      },
      {
        path: '/settings/savings-product-type',
        name: 'savings-product-type',
        component: () => import('@/views/modules/Settings/savings-product-type.vue'),
        meta: {
          title: 'Wallet Types',
          roles: ['ROLE_CREATE_WALLET_TYPE', 'ROLE_READ_WALLET_TYPE', 'ROLE_UPDATE_WALLET_TYPE']
        },
      },
      {
        path: '/settings/job-services',
        name: 'job-services',
        component: () => import('@/views/modules/Settings/job-services'),
        meta: {
          title: 'Job Services',
          roles: ['ROLE_CREATE_WALLET_TYPE', 'ROLE_READ_WALLET_TYPE', 'ROLE_UPDATE_WALLET_TYPE']
        }
      },
    ],
  },
  // ...authRouter,
];

export default new Router({
  mode: 'history', // gh-pages
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

export const asyncRoutes = [
  /** When your routing table is too long, you can split it into small modules */
  // permissionRouter,
  // vuetifyRouter,
  // ...componentsRouter,
  // nestedRouter,
  // errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true },
];
