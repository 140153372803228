<template>
  <div class="text-xs-center">
    <v-dialog scrollable v-model="dialog" data-cy="loading-dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          <h3
            class="mb-5 text-center"
            data-cy="loading-message"
            v-if="displayHtml"
            v-html="loadingMessage"
          ></h3>
          <h3 class="mb-5 text-center" data-cy="loading-message" v-else>
            {{ loadingMessage }}
          </h3>
          <v-progress-linear
            v-if="!finishLoading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-col class="text-center" v-if="finishLoading">
          <v-btn data-cy="loading-close" color="primary darken-1" @click.stop="close"
            >OK</v-btn
          >
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    message: String,
    isHtml: false,
    isDone: false,
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    isDone: {
      handler(value) {
        this.finishLoading = value;
      },
    },
    message: {
      handler(value) {
        this.loadingMessage = value;
      },
    },
    isHtml: {
      handler(value) {
        this.displayHtml = value;
      },
    },
    deep: true,
  },
  data() {
    return {
      dialog: this.isShow ?? false,
      finishLoading: this.isDone ?? false,
      loadingMessage: this.message ?? "Please stand by",
      displayHtml: this.isHtml ?? false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      if (this.displayHtml) {
        this.$emit("close-dialog", "loading");
      } else {
        this.$emit("close-dialog", "loading-error");
      }
    },
  },
};
</script>
