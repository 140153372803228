<template>
  <v-app class="container--fluid fill-height">
    <v-row align="center" justify="center" class="gray">
      <v-col cols="12" sm="8" md="4" lg="4" align="center" justify="center">
        <v-card
          class="overflow-hidden"
          max-width="344"
          elevation="2"
          outlined
          shaped
          color="blue darken-3"
          dark
        >
          <v-toolbar flat color="blue">
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light">
              Please select your tenant:
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
              :items="tenants"
              color="white"
              item-text="name"
              item-value="identifier"
              label="Tenant"
              v-model="selectedTenant"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="next"> Next </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import API from "@/api/API.js";

export default {
  name: "Tenant",
  data() {
    return {
      tenants: [],
      selectedTenant: undefined,
    };
  },
  async mounted() {
    console.log("mounted");
    this.queryData();
  },
  methods: {
    async queryData() {
      try {
        const communityListResponse = await API.getCommunities();
        console.log(communityListResponse);
        if (!communityListResponse || communityListResponse.error) {
          //error getting data
          console.log(`${communityListResponse.error}`);
        } else {
          this.tenants = communityListResponse;
          console.log(this.tenants);
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    next() {
      if (this.selectedTenant === "default") {
        this.selectedTenant = process.env.VUE_APP_DEFAULT_TENANT_REALM;
      }
      localStorage.realm = this.selectedTenant;
      location.reload();
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}

* {
  font-family: "Product Sans", "Open Sans", "OpenSans", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gray {
  background-color: darkgrey;
}
</style>
