<template>
  <v-menu
    :close-on-content-click="false"
    v-model="displayMenu"
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon large text v-on="on">
        <v-avatar size="36px">
          <img v-if="avatar" :src="avatar" alt="name" />
          <v-icon v-else x-large class="blue--text"> mdi-account </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img v-if="avatar" :src="avatar" alt="name" />
            <v-icon v-else x-large class="blue--text"> mdi-account </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user }}</v-list-item-subtitle>
          </v-list-item-content>

          <!--<v-list-item-action>-->
          <!--<v-btn-->
          <!--:class="fav ? 'red&#45;&#45;text' : ''"-->
          <!--icon-->
          <!--@click="fav = !fav"-->
          <!--&gt;-->
          <!--<v-icon>mdi-heart</v-icon>-->
          <!--</v-btn>-->
          <!--</v-list-item-action>-->
        </v-list-item>
      </v-list>
      <v-divider />
      <v-dialog v-model="dialogProfile" max-width="500px" persistent>
        <v-card>
          <loading
            :is-show="loadingDialog"
            :is-done="loadingFinished"
            :is-html="isHtml"
            :message="loadingMessage"
            v-on:close-dialog="closeLoadingDialog"
          />
          <v-card-title class="headline indigo lighten-2">
            <v-avatar class="mr-4" color="blue">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
            <div class="text-xs-center">
              <span>
                <h2>USER PROFILE</h2>
              </span>
            </div>
          </v-card-title>

          <v-card-text class="mt-7"
            ><b> USER: {{ user }} </b></v-card-text
          >
          <v-card-text
            ><b> NAME: {{ name }} </b></v-card-text
          >
          <v-card-text
            ><b>
              ROLE:
              <div class="ml-5" v-for="(role, index) in whatRole()" :key="index">
                {{ role }}
              </div>
            </b></v-card-text
          >

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
            <v-btn color="primary" text @click="displayPasswordForm">
              Change Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-list-item
        :close-on-content-click="false"
        v-for="(item, index) in menuitems"
        :key="index"
        :to="!item.href ? { name: item.name } : null"
        :href="item.href"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import API from "@/api/API";
import Loading from "@/views/components/Loading.vue";

export default {
  name: "AppProfile",
  components: {
    Loading,
  },
  data() {
    return {
      dialogProfile: false,
      isDialogClose: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      isHtml: false,
      displayMenu: false,
      menuitems: [
        {
          icon: "mdi-account",
          href: "#",
          title: "toolbar.profile",
          disabled: false,
          click: () => {
            this.showDialog();
          },
        },
        {
          icon: "mdi-settings",
          href: "#",
          title: "toolbar.settings",
          disabled: true,
          click: () => {
            console.log("this.toggleSettingsPanel()");
            this.toggleSettingsPanel();
          },
        },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "toolbar.logout",
          disabled: !this.$keycloak.authenticated,
          click: () => {
            this.logout();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "name", "avatar", "status", "authorities"]),
  },
  methods: {
    whatRole() {
      let userRoles = this.authorities.filter((role) => {
        if (role.includes("GROUP_")) {
          return role;
        }
      });

      return userRoles;
    },
    showDialog() {
      this.dialogProfile = true;
      this.isDialogClose = false;
    },
    closeDialog() {
      if (this.$refs.formEntry) {
        this.$refs.formEntry.reset();
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
      this.dialogProfile = false;
      this.isDialogClose = true;
      this.displayMenu = false;
    },
    closeLoadingDialog(type) {
      if (type === "loading") {
        this.loadingDialog = false;
        this.closeDialog();
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    displayPasswordForm() {
      console.log("pass");
      this.closeLoadingDialog("loading");
      window.open(
        `${process.env.VUE_APP_KEYCLOAK_URL}/realms/${localStorage.realm}/account/password`,
        "_blank"
      );
    },
    logout() {
      // this.$store.dispatch('LogOut');
      // this.$router.push('/signin');
      localStorage.clear();
      this.$keycloak.logoutFn({
        logoutRedirectUri: window.location.host,
      });
    },
    toggleSettingsPanel() {
      console.log("this.toggleSettingsPanel()");
      this.$vuetify.goTo(0);
      this.$store.dispatch("SettingsPanelToggle");
    },
  },
};
</script>
