<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import TheLayout from '@/views/layout/TheLayout.vue';

export default {
  name: 'App',
  components: {
    TheLayout,
  },
  computed: {
    layout() {
      return 'the-layout';
    },
  },
};
</script>

<style lang="scss">
  @font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  }

  * {
    font-family: 'Product Sans', 'Open Sans', 'OpenSans', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
