import Vue from 'vue';
import router from '@/router/index';

export default {
  /**
   * Get list of Communities
   */
  async getCommunities(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/organizations`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/organizations`
    }
    const config = {
      headers: {
        // Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data.reverse();
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of merchants."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async createCommunities(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/organization/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/organization/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      name: parameters.communityName,
      identifier: parameters.communityCode

    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of merchants."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  updateCommunities(parameters) {

  },

  /**
   * Get list of Departments
   */
  async getDepartments() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/departments`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/departments`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data.reverse();
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of departments."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async createDepartments(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/department/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/department/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      name: parameters.departmentName
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of departments."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateDepartments(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/department/edit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/department/edit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      id: parameters.departmentCode,
      name: parameters.departmentName,
      isEnabled: parameters.isEnabled,
    }
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of departments."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of Members
   */
  async getMembers() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/members`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/members`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of clients."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  /**
   * Get list of Members with Filter and Pagination
   */
  async searchMembers(offset, limit, params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/member/search`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/member/search`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }

    const body = {
      offset: offset,
      limit: limit,
      displayName: params.displayName,
      firstName: params.firstName,
      lastName: params.lastName,
      externalId: params.externalId,
      sqlSearch: params.sqlSearch,
      officeId: params.officeId
    }

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of clients."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async createMembers(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/member/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/member/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    console.log("API")
    console.log(parameters)
    let body = {}
    if (parameters.legalFormId === 1) {
      body = {
        officeId: parameters.officeId,
        firstname: parameters.firstname,
        middlename: parameters.middlename,
        lastname: parameters.lastname,
        externalId: parameters.externalId,
        tin: parameters.tin,
        legalFormId: parameters.legalFormId,
        active: false,
        mobileNo: parameters.mobileNo,
        emailAddress: parameters.emailAddress
      }
    } else { // if legalFormId is 2 (Entity)
      body = {
        officeId: parameters.officeId,
        fullname: parameters.lastname,
        externalId: parameters.externalId,
        tin: parameters.tin,
        legalFormId: parameters.legalFormId,
        active: false,
        mobileNo: parameters.mobileNo,
        emailAddress: parameters.emailAddress
      }
    }

    if (parameters.createUser) {
      body.createUser = true;
      body.roles = parameters.role
      body.userType = parameters.userType

      const encodedString = btoa(`${parameters.mobileNo}:${parameters.password}`);
      config.headers.user = encodedString;
    }

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of clients."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateMembers(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/member/edit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/member/edit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    let body = {}
    if (parameters.legalFormId === 1) {
      body = {
        id: parameters.id,
        firstname: parameters.firstname,
        middlename: parameters.middlename,
        lastname: parameters.lastname,
        externalId: parameters.externalId,
        tin: parameters.tin,
        legalFormId: parameters.legalFormId,
        active: parameters.active,
        mobileNo: parameters.mobileNo,
        emailAddress: parameters.emailAddress
      }
    } else { // if legalFormId is 2 (Entity)
      body = {
        id: parameters.id,
        fullname: parameters.lastname,
        externalId: parameters.externalId,
        tin: parameters.tin,
        legalFormId: parameters.legalFormId,
        active: parameters.active,
        mobileNo: parameters.mobileNo,
        emailAddress: parameters.emailAddress
      }
    }
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of clients."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of SavingsProducts
   */
  async getSavingsProducts() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/wallets`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/wallets`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data.reverse();
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of wallets."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async createSavingsProducts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/wallet/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/wallet/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }

    var body = {
      name: parameters.name,
      shortName: parameters.shortName,
      accountType: parameters.accountType,
      description: parameters.description,
      imageUrl: parameters.imageUrl,
      autoApproval: parameters.autoApproval ? parameters.autoApproval : 'false',
      currency: parameters.currency,
      decimalPlaces: parameters.decimalPlaces,
      multiplesOf: parameters.multiplesOf,
      nominalAnnualInterest: parameters.nominalAnnualInterest,
      interestCompoundingPeriod: parameters.interestCompoundingPeriod,
      interestPostingPeriod: parameters.interestPostingPeriod,
      interestCalculationMethod: parameters.interestCalculationMethod,
      daysInYear: parameters.daysInYear
    }

    if (parameters.accountType === 'CREDIT' && parameters.jobs && parameters.linkedWallets) {
      var selectedJobs = [];
      var selectedJobWallets = {};
      for (var i = 1; i < parameters.jobs.length; i++) {
        selectedJobs = selectedJobs.concat(parameters.jobs[i]);
        var selectedWallets = {}
        selectedWallets.walletId = parameters.linkedWallets[i];
        for (var j = 0; j < parameters.jobs[i].length; j++) {
          selectedJobWallets[(parameters.jobs[i])[j]] = selectedWallets
        }
      }
      console.log(selectedJobs.toString(), selectedJobWallets)
      body.jobs = selectedJobs.toString();
      body.linkedWallets = JSON.stringify(selectedJobWallets);
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of wallets."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateSavingsProducts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/wallet/edit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/wallet/edit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    // const body = {
    //   id: parameters.id,
    //   name: parameters.name,
    //   shortName: parameters.shortName,
    //   accountType: parameters.accountType,
    //   description: parameters.description,
    //   autoApproval: parameters.autoApproval,
    // }

    var body = {
      id: parameters.id,
      name: parameters.name,
      shortName: parameters.shortName,
      accountType: parameters.accountType,
      description: parameters.description,
      imageUrl: parameters.imageUrl,
      autoApproval: parameters.autoApproval ? parameters.autoApproval : 'false',
      currency: parameters.currency,
      decimalPlaces: parameters.decimalPlaces,
      multiplesOf: parameters.multiplesOf,
      nominalAnnualInterest: parameters.nominalAnnualInterest,
      interestCompoundingPeriod: parameters.interestCompoundingPeriod,
      interestPostingPeriod: parameters.interestPostingPeriod,
      interestCalculationMethod: parameters.interestCalculationMethod,
      daysInYear: parameters.daysInYear
    }

    if (parameters.accountType === 'CREDIT' && parameters.jobs && parameters.linkedWallets) {
      var selectedJobs = [];
      var selectedJobWallets = {};
      for (var i = 1; i < parameters.jobs.length; i++) {
        selectedJobs = selectedJobs.concat(parameters.jobs[i]);
        var selectedWallets = {}
        selectedWallets.walletId = parameters.linkedWallets[i];
        for (var j = 0; j < parameters.jobs[i].length; j++) {
          selectedJobWallets[(parameters.jobs[i])[j]] = selectedWallets
        }
      }
      console.log(selectedJobs.toString(), selectedJobWallets)
      body.jobs = selectedJobs.toString();
      body.linkedWallets = JSON.stringify(selectedJobWallets);
    }

    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of wallets."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of Saving Product Types
   */
  async getSavingProductType() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/wallet-types`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/wallet-types`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of wallet types."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  async createSavingProductType(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/wallet-type/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/wallet-type/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      name: parameters.name,
      displayName: parameters.displayName,
      description: parameters.description,
      remarks: parameters.remarks
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error creating list of wallet types."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of SavingsAccounts
   */
  async getSavingsAccounts() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/accounts`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/accounts`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data.sort((a, b) => parseInt(b.accountNo) - parseInt(a.accountNo));
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of savings accounts."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of Savings Accounts with Filter and Pagination
   */
  async searchSavingsAccounts(offset, limit) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/search`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/search`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }

    const body = {
      offset: offset,
      limit: limit
    }

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of savings accounts."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  async createSavingsAccounts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }

    console.log(parameters)
    const body = {
      clientId: parameters.clientId,
      productId: parameters.productId,
      nominalAnnualInterestRate: parameters.nominalAnnualInterestRate,
      interestCompoundingPeriodType: parameters.interestCompoundingPeriodType,
      interestCalculationType: parameters.interestCalculationType,
      interestCalculationDaysInYearType: parameters.interestCalculationDaysInYearType,
      interestPostingPeriodType: parameters.interestPostingPeriodType,
      withdrawalFeeForTransfers: parameters.withdrawalFeeForTransfers,
      allowOverdraft: parameters.allowOverdraft,
      withHoldTax: parameters.withHoldTax || false,
      enforceMinRequiredBalance: parameters.enforceMinRequiredBalance,
      creditLimit: parameters.creditLimit,
      nominalAnnualInterestRateOverdraft: parameters.nominalAnnualInterestRateOverdraft || 0,
      minRequiredOpeningBalance: parameters.minRequiredOpeningBalance,
      lockinPeriodFrequency: parameters.lockinPeriodFrequency,
      lockinPeriodFrequencyType: parameters.lockinPeriodFrequencyType,
      minRequiredBalance: parameters.minRequiredBalance,
      overdraftLimit: parameters.overdraftLimit,
      minOverdraftForInterestCalculation: parameters.minOverdraftForInterestCalculation,
    }

    if (parameters.isCredit) {
      if (parameters.jobs && parameters.linkedAccounts) {
        var selectedJobs = [];
        var selectedJobAccounts = {};
        for (var i = 1; i < parameters.jobs.length; i++) {
          selectedJobs = selectedJobs.concat([parameters.jobs[i]]);
          var selectedAccounts = [];
          var accounts = parameters.linkedAccounts[parameters.jobs[i]];
          console.log(accounts)
          for (var j = 0; j < accounts.length; j++) {
            var account = {accountId: accounts[j], position: j + 1}
            console.log(account)
            selectedAccounts.push(account);
            console.log(selectedAccounts)
          }
          selectedJobAccounts[parameters.jobs[i]] = selectedAccounts;
          console.log(selectedJobAccounts)
        }
        console.log(selectedJobs.toString(), selectedJobAccounts)
        body.jobs = selectedJobs.toString();
        body.linkedAccounts = JSON.stringify(selectedJobAccounts);
      } else {
        body.linkedAccounts = null
        body.jobs = null
      }
    }
    console.log(body)
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of savings accounts."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateSavingsAccounts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/edit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/edit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    let body = {}
    console.log(parameters)
    if (parameters.active === true) {
      body = {
        id: parameters.id,
        clientId: parameters.clientId,
        productId: parameters.productId,
        creditLimit: parameters.creditLimit
      }
    } else {
      body = {
        id: parameters.id,
        clientId: parameters.clientId,
        productId: parameters.productId,
        nominalAnnualInterestRate: parameters.nominalAnnualInterestRate,
        interestCompoundingPeriodType: parameters.interestCompoundingPeriodType,
        interestCalculationType: parameters.interestCalculationType,
        interestCalculationDaysInYearType: parameters.interestCalculationDaysInYearType,
        interestPostingPeriodType: parameters.interestPostingPeriodType,
        withdrawalFeeForTransfers: parameters.withdrawalFeeForTransfers,
        allowOverdraft: parameters.allowOverdraft,
        withHoldTax: parameters.withHoldTax || false,
        enforceMinRequiredBalance: parameters.enforceMinRequiredBalance,
        creditLimit: parameters.creditLimit,
        nominalAnnualInterestRateOverdraft: parameters.nominalAnnualInterestRateOverdraft || 0,
        minRequiredOpeningBalance: parameters.minRequiredOpeningBalance,
        lockinPeriodFrequency: parameters.lockinPeriodFrequency,
        lockinPeriodFrequencyType: parameters.lockinPeriodFrequencyType,
        minRequiredBalance: parameters.minRequiredBalance,
        overdraftLimit: parameters.overdraftLimit,
        minOverdraftForInterestCalculation: parameters.minOverdraftForInterestCalculation,
      }
    }

    if (parameters.isCredit) {
      console.log('credit')
      if (parameters.jobs && parameters.linkedAccounts) {
        console.log('has jobs and accounts')
        var selectedJobs = [];
        var selectedJobAccounts = {};
        for (var i = 1; i < parameters.jobs.length; i++) {
          selectedJobs = selectedJobs.concat([parameters.jobs[i]]);
          var selectedAccounts = [];
          var accounts = parameters.linkedAccounts[parameters.jobs[i]];
          console.log(accounts)
          for (var j = 0; j < accounts.length; j++) {
            var account = {accountId: accounts[j], position: j + 1}
            console.log(account)
            selectedAccounts.push(account);
            console.log(selectedAccounts)
          }
          selectedJobAccounts[parameters.jobs[i]] = selectedAccounts;
          console.log(selectedJobAccounts)
        }
        console.log(selectedJobs.toString(), selectedJobAccounts)
        body.jobs = selectedJobs.toString();
        body.linkedAccounts = JSON.stringify(selectedJobAccounts);
      } else {
        body.linkedAccounts = null
        body.jobs = null
      }
    }
    console.log(body)
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of savings accounts."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async getSelectableJobs(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/jobs`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/jobs`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      id: parameters.id,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of selections."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async getSelectableAccounts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/linkedAccounts`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/linkedAccounts`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      id: parameters.id,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of selections."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async getSelectableLinkedAccounts(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/selectableLInkedAccounts`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/account/selectableLInkedAccounts`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      walletId: parameters.productId,
      clientId: parameters.clientId
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of selections."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateSavingsAccountStatus(parameters) {
    // Use when CORS issue is resolved, directly communicate to server without proxy
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account?action=${parameters.newStatus}`;
    if (process.env.VUE_APP_IS_PROXY === "true") {
      // Use when CORS issue persists, and use proxy
      url = `/microfinance/api/account?action=${parameters.newStatus}`;
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      id: parameters.id,
      remarks: parameters.remarks,
    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error updating savings account's status."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of Account Transactions
   */
  async getAccountTransactions(accountId, pageNumber, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transactions?page=${pageNumber}&size=${limit}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transactions?page=${pageNumber}&size=${limit}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      accountId,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of account transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  /**
   * Get Status History of Account
   *
   * @param {Number} accountId
   * @param {Number} pageNumber
   * @param {Number} limit
   * @returns {Promise<{error}|{error: T}|{error: string}|{error: ({response}|*)}|any>}
   */
  async getAccountStatusHistory({accountId, pageNumber, limit}) {
    // Use when CORS issue is resolved, directly communicate to server without proxy
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/account/status_history?page=${pageNumber}&size=${limit}`;
    if (process.env.VUE_APP_IS_PROXY === "true") {
      // Use when CORS issue persists, and use proxy
      url = `/microfinance/api/account/status_history?page=${pageNumber}&size=${limit}`;
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      accountId,
    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status === 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting status history of account."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response);
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  /**
   * Get list of Transaction Requests
   */
  async getTransactionRequests(accountId, pageNumber, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction/requests?page=${pageNumber}&size=${limit}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction/requests?page=${pageNumber}&size=${limit}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      accountId,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of account transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  /**
   * Get list of all Transactions
   */
  async getAllTransactions(pageNumber, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transactions?page=${pageNumber}&size=${limit}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transactions?page=${pageNumber}&size=${limit}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of all transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async depositRequest(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction?request=deposit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction?request=deposit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      destination: parameters.accountId,
      amount: parameters.transactionAmount,
      remarks: parameters.remarks,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of request transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async withdrawalRequest(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction?request=withdrawal`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction?request=withdrawal`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      source: parameters.accountId,
      amount: parameters.transactionAmount,
      remarks: parameters.remarks,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of request transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async transferRequest(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction?request=transfer`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction?request=transfer`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      source: parameters.accountId,
      destination: parameters.toAccount,
      amount: parameters.transactionAmount,
      remarks: parameters.remarks,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of request transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async transactionApproval(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction/request?action=${parameters.approval}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction/request?action=${parameters.approval}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      tenant: process.env.VUE_APP_TENANT,
      referenceNumber: parameters.referenceNumber,
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of request transactions."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async transferTemplate(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/transaction/transfer/template`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/transaction/transfer/template`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    console.log(parameters)
    let body = {
      fromAccount: parameters.accountId,
    }
    if (parameters.toOffice !== undefined) {
      if (parameters.toClient !== undefined) {
        body = {
          fromAccount: parameters.accountId,
          toOffice: parameters.toOffice,
          toClient: parameters.toClient,
        }
      } else {
        body = {
          fromAccount: parameters.accountId,
          toOffice: parameters.toOffice,
        }
      }
    }

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of selections."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of User Types
   */
  async getUserTypes() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/userTypes`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/userTypes`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of user types."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  /**
   * Get list of Users
   */
  async getUsers(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/users`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/users`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data.reverse();
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of users."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async createUsers(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/user/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/user/create`
    }
    const encodedString = btoa(`${parameters.username}:${parameters.password}`);
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
        user: encodedString
      }
    }
    const body = {
      firstName: parameters.firstName,
      lastName: parameters.lastName,
      email: parameters.email,
      roles: parameters.role,
      userType: parameters.userType
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of users."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateUsers(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/user/edit`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/user/edit`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      firstName: parameters.firstName,
      lastName: parameters.lastName,
      email: parameters.email,
      username: parameters.username,
      isEnabled: parameters.isEnabled,
      roles: parameters.role,
      userType: parameters.userType
    }
    console.log(body)
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of users."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async getAllRoles() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/roles`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/roles`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of roles."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async getAllJobs() {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/jobs?page=0&size=200`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/jobs?page=0&size=200`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of jobs."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  async getAllJobList(pageNumber, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/jobs?page=${pageNumber}&size=${limit}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/jobs?page=${pageNumber}&size=${limit}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      const response = await Vue.axios.get(url, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of jobs."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async setJobRunningStatus(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/job?action=${parameters.runningStatus}`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/job?action=${parameters.runningStatus}`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`,
      }
    }
    const body = {
      jobs: parameters.jobs
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of job."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  async createJobs(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/job/create`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/job/create`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    console.log(parameters)
    let timeSubtype = {}
    if (parameters.timeType === 1) { // Daily
      timeSubtype = null
    } else if (parameters.timeType === 2) { // Weekly
      timeSubtype = {
        day: parameters.timeSubtype.day
      }
    } else if (parameters.timeType === 3) { // Monthly
      timeSubtype = {
        date: parameters.timeSubtype.date
      }
    } else if (parameters.timeType === 4) { // Annually
      timeSubtype = {
        date: parameters.timeSubtype.date,
        month: parameters.timeSubtype.month
      }
    }
    const body = {
      jobType: parameters.jobType,
      jobName: parameters.jobName,
      timeType: parameters.timeType,
      timeSubtype: timeSubtype,
      schedule: parameters.schedule,
      calculation: parameters.calculation,
      amount: parameters.amount
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of jobs."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },
  async updateJobs(parameters) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_MICROFINANCE_URL}/api/job/update`
    if (process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/microfinance/api/job/update`
    }
    const config = {
      headers: {
        tenant: this.getTenant(),
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    console.log(parameters)
    const body = {
      id: parameters.id,
      jobType: parameters.jobType,
      jobName: parameters.jobName,
      timeType: parameters.timeType,
      timeSubtype: parameters.timeSubtype,
      schedule: parameters.schedule,
      calculation: parameters.calculation,
      amount: parameters.amount
    }
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return {error: response.data.message};
      } else {
        return {error: "Sorry. Error getting list of jobs."};
      }
    } catch (error) {
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response)
        return {error: error.response.data};
      } else {
        return {error: error};
      }
    }
  },

  getTenant() {
    return localStorage.realm ? localStorage.realm === process.env.VUE_APP_DEFAULT_TENANT_REALM ? "default" : localStorage.realm : process.env.VUE_APP_TENANT
  }
}
